.inline-antd-menus {
    background-color: var(--primary-menu-background-color);

    .ant-menu-submenu-title {
        padding-left: 24px !important;
        text-transform: uppercase;
        font-size: 16px;
    }

    .ant-menu-item {
        color: #fff !important;
    }

    .ant-menu-item-only-child {
        padding-left: 24px !important;
        padding-right: 12px !important;
        height: 36px !important;
        transition: border-color 0s !important;
        a {
            color: #fff !important;
            text-decoration: none !important;
        }
    }

    .ant-menu-item-only-child.ant-menu-item-selected {
        font-weight: 600;
        color: #fff !important;
        border-left: 3px solid var(--pink-6);

        .ant-avatar {
            border: 1px solid #fff;
        }

        &:hover {
            a {
                color: #fff !important;
            }
        }
    }
}

.antd-menu-as-tabs {
    background: #fff !important;

    .ant-menu-item,
    .ant-menu-item-only-child,
    .ant-menu-item-active {
        transition: border-color 0s !important;
        margin: 0 !important;
        min-width: 170px;
        white-space: nowrap;
        text-align: center;

        &:after {
            border: none !important;
        }
        &:hover {
            color: #000 !important;
            background-color: #f1f1f2 !important;
        }
    }

    &.small-tabs {
        .ant-menu-item,
        .ant-menu-item-only-child,
        .ant-menu-item-active {
            min-width: 120px;
        }
    }

    .ant-menu-item-selected {
        color: var(--text-color-dark) !important;
        font-weight: var(--font-weight-semibold);
        border-bottom: 2px solid var(--primary-pink) !important;
    }
}

.antd-menu-as-tabs-anchors {
    background: #fff !important;
    height: 45px !important;

    .ant-menu-item,
    .ant-menu-item-only-child,
    .ant-menu-item-active {
        margin: 0 !important;
        min-width: 109px;
        white-space: nowrap;
        text-align: center;

        &:after {
            border: none !important;
        }
    }

    .ant-menu-item-selected {
        a {
            color: var(--blue-10) !important;
        }
        font-weight: var(--font-weight-semibold);
        border-bottom: 3px solid var(--primary-pink) !important;
    }

    &.ant-menu-horizontal {
        & > .ant-menu-item,
        .ant-menu-horizontal > .ant-menu-submenu {
            top: 0;
        }

        line-height: 4 !important;
    }
}
.ant-menu-item-group-list > .ant-menu-item:active {
    background-color: transparent !important;
}
.ant-menu-item-group-list > .ant-menu-item:hover {
    background-color: #f1f1f2 !important;
}

.ant-menu-vertical > .ant-menu-item:active {
    background-color: transparent !important;
}
.ant-menu-vertical > .ant-menu-item:hover {
    background-color: #f1f1f2 !important;
}
