.ant-table-row {
    a {
        transition: all ease-in-out 0.5s;
    }

    &:hover {
        a {
            color: var(--blue-6);
        }
    }
}
.ant-pagination-item:hover {
    border-color: var(--blue-5) !important;
    a {
        color: var(--blue-5) !important;
    }
}

.ant-pagination-item-active {
    border-color: var(--blue-5) !important;
    a {
        color: var(--blue-5) !important;
    }
}

.atn-pagination-prev:hover {
    color: var(--blue-5) !important;
}

.ant-table-column-sorter-inner span.active {
    color: var(--blue-5) !important;
}
.ant-table-column-sorter {
    color: #000 !important;
}
