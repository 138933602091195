.ant-modal-header {
    box-shadow: inset 0px -1px 0px var(--blue-gray-2);
}

.ant-modal-footer {
    padding-bottom: 14px !important;
    box-shadow: inset 0px 1px 0px var(--blue-gray-2);
}

.ant-modal-close-x {
    font-size: 12px !important;
}
