.ant-tabs-tab {
    font-weight: var(--font-weight-semibold);
    justify-content: center;
    min-width: 150px;

    &:hover {
        background-color: #f1f1f2;
    }

    .ant-tabs-tab-active {
        background-color: #f1f1f2;
        border-color: var(--pink-6);
    }
}

.ant-tabs.ant-tabs-top.ant-tabs-card > .ant-tabs-nav {
    background: none;
    margin-bottom: 0;
    .ant-tabs-nav-wrap {
        .ant-tabs-tab {
            border-bottom: 0;
            border-width: 2px 2px 0 2px;
            border-color: white;

            &.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #000000;
                font-weight: var(--font-weight-semibold);
            }

            &:not(.ant-tabs-tab-active) {
                background: linear-gradient(
                    180deg,
                    var(--blue-gray-1) 0%,
                    #dce0e5 100%
                );
                font-weight: var(--font-weight-regular);
                color: var(--blue-gray-6);
                border-width: 2px 2px 0 2px;
                border-color: #d5d9e0;
            }
        }
    }
}

.ant-tabs-nav-list {
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }
}
