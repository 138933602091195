.form-alert {
    margin-bottom: 16px !important;
}

.subscription-form {
    .ant-form-item {
        max-width: 300px;
    }

    .button-form-item {
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
        }
    }
}

.subscription-items-title {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    .add-item-btn {
        font-size: 20px;
        font-weight: var(--font-weight-semibold);
    }
}
