.ant-form {
    .ant-form-item-label {
        font-weight: var(--font-weight-bold);
    }

    .ant-form-item-tooltip {
        margin-left: 4px;
    }

    .unset-margin-bottom {
        margin-bottom: 0;
    }

    .ant-form-item-control-input {
        min-height: 0;
    }
}
