.antd-button-icon {
    margin-right: 8px;
}

.ant-btn {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.ant-btn,
.ant-btn-background-ghost,
.ant-btn-primary.ant-btn-background-ghost {
    &:hover:not(:disabled) {
        background-color: #f1f1f2;
        border-color: #000000;
        color: #000000;
    }
}

.ant-btn-primary:hover:not(:disabled) {
    background-color: var(--pink-7);
    color: #fff;
    border-color: var(--pink-7);
}

.ant-btn-text,
.ant-btn-link {
    border: none !important;
}

.ant-btn-link:hover:not(:disabled) {
    color: #375ea6;
}

.ant-btn-text:hover:not(:disabled) {
    color: var(--text-color);
}

.ant-btn-sm {
    line-height: 20px !important;
}

.ant-btn.ant-input-search-button {
    border-color: #d9d9d9 !important;
}

.ant-btn-link:active {
    color: var(--blue-6) !important;
}

.ant-btn:not(.ant-btn-primary, .ant-btn-link, .ant-input-search-button):focus {
    color: #000000 !important;
    border-color: #000000 !important;
}
