.login-container {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;

    .login-box {
        padding: 16px 40px;
        background-color: #ffffff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }
    .logo {
        margin-top: 32px;
        margin-bottom: 12px;
    }
    .login-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .tm {
        margin-top: 32px;
        font-size: 12px;
        color: var(--text-color-medium);
    }
}
