@import "styles/antd-button.styles.scss";
@import "styles/antd-form.styles.scss";
@import "styles/antd-menu.styles.scss";
@import "styles/antd-modal.styles.scss";
@import "styles/antd-table.styles.scss";
@import "styles/antd-tab.styles.scss";

:root {
    --font-family: Inter, sans-serif;
    --default-font-size: 14px;

    --font-weight-thin: 100;
    --font-weight-extra-light: 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --font-weight-black: 900;

    --pink-1: #fff0f2;
    --pink-2: #ffd6de;
    --pink-3: #ffadc0;
    --pink-4: #ff85a5;
    --pink-5: #ff5c8d;
    --pink-6: #ff3378;
    --pink-7: #d92164;
    --pink-8: #b31252;
    --pink-9: #8c0741;
    --pink-10: #660432;

    --blue-1: #dbe8fe;
    --blue-2: #c8d8f2;
    --blue-3: #91b0e6;
    --blue-4: #5989d9;
    --blue-5: #2261cc;
    --blue-6: #375ea6;
    --blue-7: #305293;
    --blue-8: #305293;
    --blue-9: #1c3566;
    --blue-10: #0f234a;

    --primary-pink: var(--pink-6);

    --blue-gray-1: #f0f2f5;
    --blue-gray-2: #e3e6ea;
    --blue-gray-3: #d6d9de;
    --blue-gray-4: #c3c6cb;
    --blue-gray-5: #a2a7ae;
    --blue-gray-6: #8c8c8c;

    --background-color: var(--blue-gray-1);

    --table-header-bg-color: #f5f5f5;
    --default-layout-border-color: var(--blue-gray-2);

    --primary-menu-background-color: #000000;
    --primary-menu-item-active-background-color: #2c2c2c;

    --text-color: #262626;
    --text-color-dark: #333333;
    --text-color-medium: #86959d;

    --placeholder-color: #bdc7cb;

    --main-dark-blue: #1e3545;
}

* {
    box-sizing: border-box;
}

html {
    font-size: var(--default-font-size);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    font-family: var(--font-family);
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: var(--font-family);
}

#root {
    height: 100%;
}

.App {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--text-color-dark);
}

.container {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 100%;
}

.flex-container {
    display: flex;

    &.center {
        align-items: center;
    }

    &.vertical {
        flex-direction: column;
    }

    &.horizontal {
        flex-direction: row;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    .flex-left {
        width: 50%;
        margin-right: auto;
        text-align: left;
    }

    .flex-right {
        width: 50%;
        margin-left: auto;
        text-align: right;
    }

    .col {
        flex: 1;
    }
    .space-between {
        justify-content: space-between;
    }
}

.content-wrapper {
    flex: 1;
    height: 100%;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border: none !important;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    background: var(--default-layout-border-color);
    border-radius: 100px;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    color: var(--blue-6);
}
.ant-table-pagination.ant-pagination {
    margin: 16px 0 0 !important;
}
.ant-statistic-title {
    margin: 0;
}
